import React from 'react';
import _map from 'lodash/map';

import Error from './error';

import { Spin, Tooltip, Tag, Row, Col, Card } from 'antd';
import MomentComponent from 'react-moment';
import moment from 'moment';
import { withFirebase } from './Firebase/FirebaseContext';

class FreeRooms extends React.Component {
  state = {
    loading: true,
    error: false,
    rooms: [],
  };

  constructor(props) {
    super(props);
    props.firebase && this.fetchData(props.firebase);
  }

  fetchData = firebase => {
    if (firebase) {
      return firebase
        .fetchFunction(process.env.GATSBY_GET_CALENDAR)
        .then(response => {
          console.log(response);
          this.setState({ loading: false, rooms: response.rooms });
        })
        .catch(error => {
          this.setState({ error: true });
          console.error(`An Error has occured: ${error.message}`);
        });
    }
  };

  formatTitle = (room, isFull) => {
    return (
      <>
        {isFull ? (
          <Tag color="red">occupied</Tag>
        ) : (
          <Tag color="green">free</Tag>
        )}
        <Tooltip title={room.description}>{room.name}</Tooltip>
      </>
    );
  };

  mapRooms = () => {
    return _map(this.state.rooms, (room, index) => {
      const now = moment().add(0 + 14, 'hours');
      let isFull =
        room.events.length &&
        now.isAfter(room.events[0].start) &&
        now.isBefore(room.events[0].end);

      return (
        <Col xs={24} sm={12} md={12} lg={8} xl={6} key={index}>
          <Card
            title={this.formatTitle(room, isFull)}
            bordered={true}
            bodyStyle={{ minHeight: '200px' }}
            style={{ marginBottom: '10px' }}
          >
            {room.events.length === 0 && (
              <Tag style={{ background: '#fff', borderStyle: 'dashed' }}>
                No upcoming events
              </Tag>
            )}
            {room.events.map(function(event, i) {
              return (
                <p>
                  <strong>
                    <MomentComponent format="HH:mm">
                      {event.start}
                    </MomentComponent>{' '}
                    -{' '}
                    <MomentComponent format="HH:mm">
                      {event.end}
                    </MomentComponent>
                  </strong>
                  &nbsp;
                  {event.name}
                </p>
              );
            })}
          </Card>
        </Col>
      );
    });
  };

  componentWillReceiveProps(nextProps) {
    nextProps.firebase &&
      nextProps.firebase !== this.props.firebase &&
      this.fetchData(nextProps.firebase);
  }

  render() {
    if (this.state.loading) {
      return <Spin size="large" />;
    }

    if (this.state.error) {
      return <Error />;
    }
    return (
      <>
        <Row gutter={10}>{this.mapRooms()}</Row>
        <MomentComponent format="HH:mm">
          {moment().add(0 + 14, 'hours')}
        </MomentComponent>
      </>
    );
  }

  /*componentDidMount() {
    this.fetchData();
  }*/
}

export default withFirebase(FreeRooms);
