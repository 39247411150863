// @flow
import React from 'react';
import styled from 'styled-components';

const StyledError = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 0, 0, 0.8);
  display: block;
`;

const Error = () => (
  <StyledError>
    Oops, something went terribly wrong. Looking for a fix. Try it in a moment
    please.
  </StyledError>
);

export default Error;
