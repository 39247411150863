import React, { Component } from 'react';

import getFirebase from './Firebase';
import FirebaseContext from './Firebase/FirebaseContext';
import withAuthentication from './Session/withAuthentication';

class Layout extends Component {
  state = {
    firebase: null,
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.setState({ firebase: getFirebase() });
    }
  }

  render() {
    return (
      <FirebaseContext.Provider value={this.state.firebase}>
        <AppWithAuthentication {...this.props} />
      </FirebaseContext.Provider>
    );
  }
}

const AppWithAuthentication = withAuthentication(props => (
  <>{props.children}</>
));

export default Layout;
