import React from 'react';

import FreeRooms from '../components/freeRooms';
import { Layout as ALayout } from 'antd';
import Layout from '../components/layout';

import 'antd/dist/antd.css';

const { Header, Content, Footer } = ALayout;

const IndexPage = props => {
  return (
    <Layout>
      <ALayout className="layout">
        <Header>
          <div className="logo" />
        </Header>
        <Content style={{ padding: '0 50px' }}>
          <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>
            <FreeRooms />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }} />
      </ALayout>
    </Layout>
  );
};

export default IndexPage;
