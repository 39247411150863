const config = {
  apiKey: 'AIzaSyC72Q4ReJCNcuVomcrHtLgRUp6flhljmyE',
  authDomain: 'ooo-calendar-d56ea.firebaseapp.com',
  databaseURL: 'https://ooo-calendar-d56ea.firebaseio.com',
  projectId: 'ooo-calendar-d56ea',
  storageBucket: 'ooo-calendar-d56ea.appspot.com',
  messagingSenderId: '810797217765',
};

class Firebase {
  constructor(app) {
    app.initializeApp(config);
    this.auth = app.auth();

    this.authProvider = new app.auth.GoogleAuthProvider();
    this.authProvider.setCustomParameters({
      hd: 'oakslab.com',
    });
  }

  signInWithRedirect = () => {
    this.auth.onAuthStateChanged(user => {
      if (!user) {
        this.auth.signInWithRedirect(this.authProvider);
      }
      this.user = user;
    });
  };

  fetchFunction = (functionName, data, options, jsonify = true) => {
    const sendRequest = token => {
      return fetch(
        `${process.env.GATSBY_CLOUD_FUNCTIONS_URL}/${functionName}`,
        {
          ...(options || {}),
          headers: {
            ...(options ? options.headers || {} : {}),
            Authorization: `Bearer ${token}`, // <- including authorization header
          },
          body: options
            ? options.body || JSON.stringify(data)
            : JSON.stringify(data),
        }
      ).then(response => (jsonify ? response.json() : response));
    };

    return this.auth.currentUser
      ? this.auth.currentUser
          .getIdToken()
          .then(sendRequest)
          .then(response => (jsonify ? response.json() : response))
      : new Promise((resolve, reject) =>
          this.auth.onAuthStateChanged(user => {
            if (!user) {
              return this.signInWithRedirect();
            }
            user
              .getIdToken()
              .then(sendRequest)
              .then(resolve)
              .catch(reject);
          })
        );
  };
}

let firebaseInstance;
function getFirebase() {
  if (typeof window.firebase !== 'undefined') {
    if (!firebaseInstance) {
      firebaseInstance = new Firebase(window.firebase);
    }
    return firebaseInstance;
  }
}

export default getFirebase;
